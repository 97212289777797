import { Injectable } from '@angular/core';
import { BizHttp } from '../../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../../framework/core/http/http';
import { CompanySalary } from '../../../unientities';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
export enum CompanySalaryBaseOptions {
    NettoPayment = 0,
    SpesialDeductionForMaritim = 1,
    Svalbard = 2,
    PayAsYouEarnTaxOnPensions = 3,
    JanMayenAndBiCountries = 4,
    NettoPaymentForMaritim = 6,
    TaxFreeOrganization = 7,
}
@Injectable({ providedIn: 'root' })
export class CompanySalaryService extends BizHttp<CompanySalary> {
    constructor(http: UniHttp) {
        super(http);
        this.relativeURL = CompanySalary.RelativeUrl;
        this.entityType = CompanySalary.EntityType;
    }

    updateProjectCostOnEmployeesAndWageTypes(employees = [], wageTypes = []): Observable<any> {
        return this.http
            .asPUT()
            .withEndPoint(this.relativeURL + '?action=update_project_cost_employee_and_wagetype')
            .withBody({ employees, wageTypes })
            .send()
            .pipe(map((response) => response.body));
    }

    setProjectCostAdjustment(useProjectCostAdjustment: boolean): Observable<any> {
        return this.http
            .asPUT()
            .withEndPoint(
                this.relativeURL +
                    `?action=toggle-project-cost-adjustment&projectCostAdjustmentValue=${useProjectCostAdjustment}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    getCompanySalary(): Observable<CompanySalary> {
        return super.GetAll('').pipe(map((response) => response[0]));
    }

    getBaseOptions(compSal: CompanySalary): CompanySalaryBaseOptions[] {
        return Object.keys(CompanySalaryBaseOptions)
            .filter((key) => compSal && compSal['Base_' + key])
            .map((key) => CompanySalaryBaseOptions[key]);
    }

    checkOrUpdateCompanySalaryUseEmployeeLedger() {
        return this.getCompanySalary().pipe(
            switchMap((companySalary) => {
                // if useEmployeeLedger is None, set to OnDemand
                if (companySalary.UseEmployeeLedger !== 0) {
                    return of(companySalary);
                } else {
                    companySalary.UseEmployeeLedger = 2;
                    return this.Put(companySalary.ID, companySalary);
                }
            }),
        );
    }
}
