import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { ToastService, ToastType, ToastTime } from '@uni-framework/uniToast/toastService';
import { BizHttp, UniHttp } from '@uni-framework/core/http';
import { WageType, LimitType } from '@uni-entities';
import { ErrorService } from '@app/services/common/errorService';
import { SalaryTransactionService } from '@app/services/salary/payrollRun/salaryTransactionService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { HttpParams } from '@angular/common/http';

export enum WageTypeBaseOptions {
    VacationPay = 0,
    AGA = 1,
}
@Injectable({ providedIn: 'root' })
export class WageTypeService extends BizHttp<WageType> {
    private readOnlyProps: string[] = [
        '_baseOptions',
        'Base_Payment',
        'SpecialAgaRule',
        'taxtype',
        'StandardWageTypeFor',
    ];
    defaultExpand: any = ['SupplementaryInformations', 'WageTypeConnecting', 'WageTypeConnecting.WageType'];

    private limitTypes: { Type: LimitType; Name: string }[] = [
        { Type: LimitType.None, Name: 'Ingen' },
        { Type: LimitType.Amount, Name: 'Antall' },
        { Type: LimitType.Sum, Name: 'Beløp' },
    ];

    constructor(
        protected http: UniHttp,
        private errorService: ErrorService,
        private salaryTransactionService: SalaryTransactionService,
        private statisticsService: StatisticsService,
        private toastService: ToastService,
    ) {
        super(http);
        this.relativeURL = WageType.RelativeUrl;
        this.entityType = WageType.EntityType;
    }

    public getLimitTypes() {
        return of(this.limitTypes);
    }

    public syncWagetypes() {
        return super.PutAction(null, 'synchronize');
    }

    public createAndUpdateStandardWagetypes() {
        return super.PutAction(null, 'create-and-update-standard-wagetypes');
    }

    public syncAndCompareStandardWagetypes() {
        return super.PutAction(null, 'sync-and-compare-standard-wagetypes');
    }

    public save(wt: WageType): Observable<WageType> {
        const obs = wt.ID ? super.Put(wt.ID, wt) : super.Post(wt);

        return obs.pipe(tap(() => this.cleanUpCache()));
    }

    public hasAnyWageTypes(): Observable<boolean> {
        return this.statisticsService
            .GetAllUnwrapped('model=WageType' + '&select=count(id) as count')
            .pipe(map((x) => !!x[0].count));
    }

    private cleanUpCache() {
        this.salaryTransactionService.invalidateCache();
    }

    public getWageType(id: number | string, expand: string[] = null): Observable<any> {
        if (id === 0) {
            if (expand) {
                return this.GetNewEntity(expand);
            }
            return this.GetNewEntity(this.defaultExpand);
        } else {
            if (expand) {
                return this.Get(id, expand);
            }
            return this.Get(id, this.defaultExpand);
        }
    }

    public usedInPayrollrun(ID: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(this.relativeURL + '/' + ID + '?action=used-in-payrollrun')
            .send()
            .pipe(map((response) => response.body));
    }

    public manageReadOnlyIfCalculated(fields: any[], readOnly: boolean) {
        return fields.map((field) => {
            if (this.readOnlyProps.some((prop) => prop === field.Property)) {
                field.ReadOnly = readOnly;
            }
            return field;
        });
    }

    public getOrderByWageTypeNumber(filter: string = '', expands: string[] = null, orderby: string = '') {
        return super.GetAll(`${filter}&orderBy=WageTypeNumber${orderby}`, expands ? expands : this.defaultExpand);
    }

    public getPrevious(wageTypeNumber: number, expands: string[] = null) {
        return super
            .GetAll(
                `filter=WageTypeNumber lt ${wageTypeNumber}&top=1&orderBy=WageTypeNumber desc`,
                expands ? expands : this.defaultExpand,
            )
            .pipe(map((resultSet) => resultSet[0]));
    }

    public getNext(wageTypeNumber: number, expands: string[] = null) {
        return super
            .GetAll(
                `filter=WageTypeNumber gt ${wageTypeNumber}&top=1&orderBy=WageTypeNumber`,
                expands ? expands : this.defaultExpand,
            )
            .pipe(map((resultSet) => resultSet[0]));
    }

    public deleteWageType(id: number): Observable<boolean> {
        return super.Remove(id).pipe(catchError((err, obs) => this.errorService.handleRxCatch(err, obs)));
    }

    public washWageType(wt: WageType): WageType {
        wt.AccountNumber = wt.AccountNumber || 0;
        wt.AccountNumber_balance = wt.AccountNumber_balance || 0;
        wt.Rate = wt.Rate || 0;
        wt.RateFactor = wt.RateFactor || 0;
        wt.Limit_newRate = wt.Limit_newRate || 0;
        wt.Limit_value = wt.Limit_value || 0;
        wt.Limit_WageTypeNumber = wt.Limit_WageTypeNumber || 0;

        return wt;
    }

    public wagetypeMaintainanceNotify(wt: WageType) {
        if (wt.Systemtype != null) {
            this.toastService.addToast(
                `Automatisk vedlikehold`,
                ToastType.warn,
                ToastTime.medium,
                `Dersom du lagrer lønnsarten med disse endringene vil ikke systemleverandør oppdatere lønnsarten lenger.`,
            );
        }
    }

    getWageTypeLookupFunction(urlParams: HttpParams, filter?: string) {
        let params = urlParams || new HttpParams();

        const filtersFromUniTable = urlParams.get('filter');
        const filters = filtersFromUniTable ? [filtersFromUniTable] : [];
        if (filters?.length > 0) {
            const splitted = filters[0].split(`'`);

            let searchValue;
            if (splitted.length > 1 && splitted[1] !== undefined) {
                searchValue = splitted[1];
            }
            filters.push(`contains(keywords, '${searchValue}')`);

            if (filters[0] === '') {
                filters.shift();
            }
        }
        params = params.set(
            'filter',
            `${filters.length ? '(' + filters.join(' or ') + ')' : ''}${filters.length && filter ? ' and ' + filter : (filter ?? '')}`,
        );
        return this.GetAllByHttpParams(params);
    }
}
