import { Injectable } from '@angular/core';
import { BizHttp, RequestMethod, UniHttp } from '@uni-framework/core/http';
import {
    PayrollRun,
    PostingSummaryDraft,
    EmployeeCategory,
    SalBalType,
    TaxType,
    WorkItemToSalary,
    Employee,
    LocalDate,
    SalaryTransaction,
    WageType,
    SpecialAgaRule,
} from '@uni-entities';
import { Observable, forkJoin, of } from 'rxjs';
import { StatisticsService } from '@app/services/common/statisticsService';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { AssignmentDetails } from '@app/services/accounting/supplierInvoiceService';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ITaxAndVacationPayResult } from '@app/components/salary/payroll-run/services/salary-payroll-run.service';
import { SalaryTransactionService } from './salaryTransactionService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { CompanySalaryService } from '@app/services/salary/companySalary/companySalaryService';
import { ErrorService } from '@app/services/common/errorService';
import { WageTypeService } from '@app/components/salary/shared/services/wageType/wageTypeService';
import { UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';

export enum StatusCodePayment {
    Queued = 44001,
    TransferredToBank = 44002, // Note: NOT in Use yet
    Failed = 44003,
    Completed = 44004,
    ReadyForTransfer = 44005,
    ReceiptParsed = 44006,
}

export enum PayrollRunPaymentStatus {
    None = 0,
    SentToPayment = 1,
    PartlyPaid = 2,
    Paid = 3,
}

export enum SalaryBookingType {
    Dimensions = 0,
    DimensionsAndBalance = 1,
    NoDimensions = 2,
}

export enum ActionOnPaymentReload {
    DoNothing = 0,
    SentToBank = 1,
    SentToPaymentList = 2,
}

export interface IPaycheckReportSetup {
    EmpIDs: number[];
    Mail: IPaycheckEmailInfo;
}

export interface IPaycheckEmailInfo {
    ReportID?: number;
    Subject?: string;
    Message?: string;
    GroupByWageType?: boolean;
    RequestedSendDate?: LocalDate;
    UsePayDate?: boolean;
    Localization?: string;
}

@Injectable({ providedIn: 'root' })
export class PayrollRunService extends BizHttp<PayrollRun> {
    readonly payStatusProp = '_payStatus';

    public payStatusTable: any = [
        { ID: null, text: 'Opprettet' },
        { ID: 0, text: 'Opprettet' },
        { ID: 1, text: 'Avregnet' },
        { ID: 2, text: 'Godkjent' },
        { ID: 3, text: 'Sendt til utbetaling' },
        { ID: 4, text: 'Utbetalt' },
        { ID: 5, text: 'Bokført' },
        { ID: 6, text: 'Tildelt godkjenning' },
        { ID: 7, text: 'Avvist' },
    ];

    aga5PercentRefundWageType: WageType = null;

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
        private financialYearService: FinancialYearService,
        private companySalaryService: CompanySalaryService,
        private errorService: ErrorService,
        private salaryTransactionService: SalaryTransactionService,
        private wageTypeService: WageTypeService,
        private modalService: UniModalService,
        private toastService: ToastService,
    ) {
        super(http);
        this.relativeURL = PayrollRun.RelativeUrl;
        this.entityType = PayrollRun.EntityType;
    }

    public emailPaychecks(runID: number, setup: IPaycheckReportSetup) {
        return this.ActionWithBody(runID, setup, 'email-paychecks', RequestMethod.Put);
    }

    public savePayrollRun(payrollRun: PayrollRun): Observable<PayrollRun> {
        return payrollRun.ID ? this.Put(payrollRun.ID, payrollRun) : this.Post(payrollRun);
    }

    public getStatus(payrollRun: PayrollRun) {
        if (payrollRun) {
            return this.payStatusTable.find((x) => x.ID === payrollRun.StatusCode);
        } else {
            return this.payStatusTable.find((x) => x.ID === null);
        }
    }

    public getSelectableEmployeesOnPayrollRun(id: number, expands: string[] = []): Observable<Employee[]> {
        return this.GetAction(id, 'selectable-employees-on-payroll-run', `expand=${expands.join(',')}`);
    }

    public getEmployeesOnPayroll(id: number, expands: string[]): Observable<Employee[]> {
        return this.GetAction(id, 'employeesonrun', `expand=${expands.join(',')}`);
    }

    public getOTPExportData(payrollIDs: string, otpPeriode: number, otpYear: number, asXml: boolean = false) {
        return this.GetAction(
            null,
            'otp-export',
            `runs=${payrollIDs}&month=${otpPeriode}&year=${otpYear}&&asXml=${asXml}`,
        );
    }

    public getTimeToTransfer(id: number, todate?: Date): Observable<WorkItemToSalary[]> {
        const dateFilter = todate ? `toDate=${rigDate(todate).format('YYYY-MM-DD')}` : '';
        return this.GetAction(id, 'time-to-salary-selection', dateFilter);
    }

    public createTimeTransactions(payrun: number, timeList: number[]) {
        this.invalidateCache();
        this.salaryTransactionService.invalidateCache();
        return this.ActionWithBody(payrun, timeList, 'work-items-to-transes');
    }

    public assign(payrollRunID: number, assignmentDetails: AssignmentDetails): Observable<any> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}/${payrollRunID}?action=assignTo`)
            .withBody(assignmentDetails)
            .send()
            .pipe(map((response) => response.body));
    }

    public getPostingSummaryDraft(ID: number): Observable<PostingSummaryDraft> {
        return super.GetAction(ID, 'postingsummarydraft');
    }

    public getAll(queryString: string, includePayments: boolean = false): Observable<PayrollRun[]> {
        const year = this.financialYearService.getActiveYear();

        let queryList = queryString.split('&');
        let filter = queryList.filter((x) => x.toLowerCase().includes('filter'))[0] || '';
        filter = filter.toLowerCase();
        queryList = queryList.filter((x) => !x.toLowerCase().includes('filter'));
        if (!filter.toLowerCase().includes('year(paydate)')) {
            filter =
                'filter=' + (filter ? `(${filter.replace('filter=', '')}) and ` : '') + `(year(PayDate) eq ${year})`;
        }

        queryList.push(filter);
        return this.GetAll(queryList.join('&')).pipe(
            switchMap((runs) => {
                if (!runs.length || !includePayments) {
                    return of(runs || []);
                }

                return this.getPaymentsOnRun(runs, year).pipe(
                    catchError((err) => {
                        this.errorService.handle(err);
                        return of([]);
                    }),
                    map((payments) => this.setPaymentStatusOnPayrollList(runs, payments)),
                );
            }),
        );
    }

    public getLatestSettledRun(year?: number): Observable<PayrollRun> {
        return super
            .GetAll(`filter=StatusCode ge 1 ${year ? 'and year(PayDate) eq ' + year : ''}&top=1&orderby=PayDate DESC`)
            .pipe(map((resultSet) => resultSet[0]));
    }

    public getEarliestOpenRunOrLatestSettled(setYear?: number): Observable<PayrollRun> {
        const currYear = setYear ? setYear : this.financialYearService.getActiveYear();

        return of(currYear).pipe(
            switchMap((year) => this.getEarliestOpenRun(year)),
            switchMap((run) => (run ? of(run) : this.getLatestSettledRun(currYear))),
        );
    }
    public getEarliestOpenRun(setYear?: number): Observable<PayrollRun> {
        const year = setYear ? setYear : this.financialYearService.getActiveYear();

        return super
            .GetAll(
                `filter=(StatusCode eq null or StatusCode le 1) and year(PayDate) eq ${year}` +
                    `&top=1` +
                    `&orderby=PayDate ASC`,
            )
            .pipe(map((result) => result[0]));
    }

    public saveCategory(id: number, category: EmployeeCategory) {
        const saveObs = category.ID ? this.http.asPUT() : this.http.asPOST();
        return saveObs
            .usingBusinessDomain()
            .withEndPoint(this.relativeURL + '/' + id + '/category/' + category.ID)
            .withBody(category)
            .send()
            .pipe(map((response) => response.body));
    }

    public getPaymentsOnRun(runs: PayrollRun[], year: number) {
        return this.statisticsService
            .GetAll(
                `model=Tracelink` +
                    `&select=PayrollRun.ID as ID,Payment.StatusCode as StatusCode,Payment.PaymentDate as PaymentDate,` +
                    `sum(casewhen(Payment.StatusCode ne ${StatusCodePayment.Completed},1,0)) as notPaid,` +
                    `sum(casewhen(Payment.StatusCode eq ${StatusCodePayment.Completed},1,0)) as paid` +
                    `&filter=SourceEntityName eq 'PayrollRun' and DestinationEntityName eq 'Payment' and Payment.PaymentDate le '${year}-12-31T23:59:59Z' ` +
                    `and Payment.PaymentDate ge '${year}-01-01T00:00:01Z' and PayrollRun.ID in (${runs.map((x) => x.ID)}) ` +
                    `&join=Tracelink.DestinationInstanceId eq Payment.ID as Payment and Tracelink.SourceInstanceId eq PayrollRun.ID as PayrollRun`,
            )
            .pipe(map((x) => x.Data));
    }

    public getPaymentIDsQueuedOnPayrollRun(run: PayrollRun) {
        return this.statisticsService
            .GetAll(
                `model=Tracelink` +
                    `&select=Payment.ID as PaymentID` +
                    `&filter=SourceEntityName eq 'PayrollRun' and DestinationEntityName eq 'Payment' ` +
                    `and PayrollRun.ID eq ${run.ID} ` +
                    `and Payment.StatusCode eq ${StatusCodePayment.Queued}` +
                    `&join=Tracelink.DestinationInstanceId eq Payment.ID as Payment and Tracelink.SourceInstanceId eq PayrollRun.ID as PayrollRun`,
            )
            .pipe(map((x) => x.Data));
    }

    private setPaymentStatusOnPayrollList(payrollRuns: PayrollRun[], payments?: any[]): PayrollRun[] {
        return payrollRuns
            ? payrollRuns.map((run) =>
                  this.markPaymentStatus(run, payments ? payments.filter((p) => p.ID === run.ID) : []),
              )
            : [];
    }

    public markPaymentStatus(payrollRun: PayrollRun, payments?: any[]): PayrollRun {
        payments = payments || payrollRun['Payments'] || [];
        if (payments.length <= 0) {
            payrollRun[this.payStatusProp] = PayrollRunPaymentStatus.None;
        } else if (payments.some((pay) => !pay.paid && !!pay.notPaid)) {
            payrollRun[this.payStatusProp] = PayrollRunPaymentStatus.SentToPayment;
        } else if (payments.some((pay) => !!pay.paid && !!pay.notPaid)) {
            payrollRun[this.payStatusProp] = PayrollRunPaymentStatus.PartlyPaid;
        } else if (payments.some((pay) => !!pay.paid && !pay.notPaid)) {
            payrollRun[this.payStatusProp] = PayrollRunPaymentStatus.Paid;
        }
        return payrollRun;
    }

    public getEmployeeCount(payrollRunID: number): Observable<number> {
        return this.statisticsService
            .GetAll(
                `select=EmployeeID as EmployeeID` +
                    `&model=SalaryTransaction` +
                    `&filter=PayrollRunID eq ${payrollRunID}` +
                    `&distinct=true`,
            )
            .pipe(map((result) => result.Data?.length || 0));
    }

    public getTaxAndVacationPay(payrollRunID: number): Observable<ITaxAndVacationPayResult> {
        const selects = [
            'sum(casewhen(Wagetype.Base_EmploymentTax ne 0,Sum,0)) as baseEmploymentTax',
            'sum(casewhen(Wagetype.Base_Vacation ne 0,Sum,0)) as vacationPayBase',
            `sum(casewhen(Wagetype.TaxType eq ${TaxType.Tax_Percent} or Wagetype.TaxType eq ${TaxType.Tax_Table}\,Sum\,0)) as taxBase`,
            `multiply(sum(casewhen(Wagetype.IncomeType eq 'Forskuddstrekk' or Wagetype.IncomeType eq 'forskuddstrekk'\,Sum\,0)),-1) as tax`,
        ];
        return this.statisticsService
            .GetAllUnwrapped(
                `select=${selects.join(',')}` +
                    `&model=SalaryTransaction` +
                    `&filter=PayrollRunID eq ${payrollRunID}` +
                    `&expand=Wagetype`,
            )
            .pipe(map((result) => result[0]));
    }

    public getSalaryPayBase(payrollRunID: number): Observable<number> {
        return forkJoin([
            this.getSalaryTransactionNetPay(payrollRunID),
            this.getSalaryTransactionTax(payrollRunID),
            this.getSalaryBalancePay(payrollRunID),
            this.getSalaryBalancePayFromSalaryTransaction(payrollRunID),
        ]).pipe(map((result) => result.reduce((acc, current) => acc + current, 0)));
    }

    getEmployeesWithAgaRefundAnd5PercentAga(salarytransactions: SalaryTransaction[]) {
        if (salarytransactions?.some((s) => s.Wagetype?.SpecialAgaRule === 1)) {
            const year = this.financialYearService.getActiveFinancialYear().Year;
            const employeeIDs = salarytransactions
                .map((item) => item.EmployeeID)
                .filter((value, index, self) => self.indexOf(value) === index);
            return this.statisticsService.GetAllUnwrapped(
                'model=SalaryTransaction' +
                    '&select=isnull(sum(agaextra),0) as agaextra,employeeID as employeeID,EmployeeNumber as EmployeeNumber,BusinessRelationInfo.Name as BRName' +
                    `&filter=payrollrun.statuscode ge 1 and year(payrollrun.paydate) eq ${year} and employeeid in (${employeeIDs})` +
                    '&expand=Payrollrun,Employee,Employee.BusinessRelationInfo' +
                    '&having=isnull(sum(agaextra),0) gt 0',
            );
        } else {
            return of([]);
        }
    }

    wageTypeforAga5PercentRefund(): Observable<WageType> {
        return this.wageTypeService
            .GetAll(`filter=SpecialAgaRule eq ${SpecialAgaRule.AgaRefund5Percent}`)
            .pipe(map((wagetypes: WageType[]) => (wagetypes.length === 1 ? wagetypes[0] : null)));
    }

    checkAgaExtra(hasSpecialAgaRuleOne: boolean) {
        let obsWageType$ =
            this.aga5PercentRefundWageType == null
                ? this.wageTypeforAga5PercentRefund()
                : of(this.aga5PercentRefundWageType);
        return obsWageType$.pipe(
            tap((agaRefundWageType) => {
                if (agaRefundWageType) {
                    this.aga5PercentRefundWageType = agaRefundWageType;
                }
                if (hasSpecialAgaRuleOne) {
                    this.open5PercentWarningModal();
                }
            }),
        );
    }

    private open5PercentWarningModal(): void {
        let wageTypeText: string = '';
        if (this.aga5PercentRefundWageType) {
            wageTypeText = `${this.aga5PercentRefundWageType.WageTypeNumber} - ${this.aga5PercentRefundWageType.WageTypeName} `;
        } else {
            this.toastService.addToast(
                'Lønnsart for Refusjon sykelønn (med ekstra 5% aga) finnes ikke. Prøv og synkroniser lønnsarter.',
                ToastType.warn,
                ToastTime.forever,
            );
            wageTypeText = 'som mangler';
        }
        let message = `Refusjoner fra NAV skal redusere grunnlaget for ekstra 5% aga. Du må derfor føre hele refusjonen i lønnsart  ${wageTypeText}. `;
        this.modalService.confirm({
            header: 'Refusjon av sykepenger',
            message: `${message}`,
            buttonLabels: {
                accept: 'Ok',
            },
        });
    }

    private getSalaryTransactionTax(runID: number): Observable<number> {
        return this.statisticsService
            .GetAllUnwrapped(
                `model=SalaryTransaction` +
                    `&select=sum(Sum) as sum` +
                    `&expand=WageType` +
                    `&filter=` +
                    `(Wagetype.IncomeType eq 'Forskuddstrekk' or Wagetype.IncomeType eq 'forskuddstrekk') ` +
                    `and PayrollRunID eq ${runID} `,
            )
            .pipe(map((data) => -data[0]?.sum || 0));
    }

    private getSalaryBalancePay(runID: number): Observable<number> {
        return this.companySalaryService.getCompanySalary().pipe(
            map((comp) => {
                const instalmentFilter = `SalaryBalance.InstalmentType ne ${SalBalType.Advance}`;
                return comp.PostGarnishmentToTaxAccount
                    ? `${instalmentFilter}`
                    : `(${instalmentFilter} and SalaryBalance.InstalmentType ne ${SalBalType.Garnishment})`;
            }),
            switchMap((instalmentFilter) =>
                this.statisticsService.GetAllUnwrapped(
                    `select=sum(SalaryTransaction.Sum) as sum` +
                        `&model=SalaryBalanceLine` +
                        `&expand=SalaryBalance,SalaryTransaction` +
                        `&filter=` +
                        `${instalmentFilter} ` +
                        `and (SalaryBalance.CreatePayment ne 0 or SalaryBalance.InstalmentType eq ${SalBalType.Garnishment}) ` +
                        `and isnull(SalaryBalance.SupplierID,0) ne 0 ` +
                        `and SalaryTransaction.PayrollRunID eq ${runID} `,
                ),
            ),
            map((data) => -data[0]?.sum || 0),
        );
    }
    private getSalaryBalancePayFromSalaryTransaction(runID: number): Observable<number> {
        return this.companySalaryService.getCompanySalary().pipe(
            map((comp) => {
                const instalmentFilter = `SalaryBalance.InstalmentType ne ${SalBalType.Advance}`;
                return comp.PostGarnishmentToTaxAccount
                    ? `${instalmentFilter}`
                    : `(${instalmentFilter} and SalaryBalance.InstalmentType ne ${SalBalType.Garnishment})`;
            }),
            switchMap((instalmentFilter) =>
                this.statisticsService.GetAllUnwrapped(
                    `select=sum(SalaryTransactions.Sum) as sum` +
                        `&model=SalaryBalance` +
                        `&expand=SalaryTransactions` +
                        `&filter=` +
                        `${instalmentFilter} ` +
                        `and (SalaryBalance.CreatePayment ne 0 or SalaryBalance.InstalmentType eq ${SalBalType.Garnishment}) ` +
                        `and isnull(SalaryBalance.SupplierID,0) ne 0 ` +
                        `and SalaryTransactions.PayrollRunID eq ${runID} `,
                ),
            ),
            map((data) => -data[0]?.sum || 0),
        );
    }

    private getSalaryTransactionNetPay(runID: number): Observable<number> {
        return this.statisticsService
            .GetAllUnwrapped(
                `model=SalaryTransaction` +
                    `&select=sum(Sum) as sum` +
                    `&filter=` +
                    `Wagetype.Base_Payment ne 0 ` +
                    `and PayrollRunID eq ${runID} ` +
                    `&expand=Wagetype`,
            )
            .pipe(map((data) => data[0]?.sum || 0));
    }
}
